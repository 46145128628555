.loginForm {
  justify-content: center;
  min-height: 90vh;
}
.buttonBlock {
  width: 100%;
}
.loginBackground {
  max-width: 800px;
  margin: 2em auto;
  text-align: center;
}
.loginItem {
  padding: 15px 20px;
  text-align: center;
  width: 100%;
}

.logoPosition {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textForm {
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.errorMessage {
  font-size: 12px;
  text-align: left;
  width: 100%;
}
