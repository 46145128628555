.loginItem {
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}
.buttonBlock {
  width: 100%;
}
.textForm {
  font-size: 12px;
  text-align: left;
  width: 100%;
}

.globalErrorMesage {
  font-size: 12px;
  text-align: center;
  width: 100%;
}
