.postionStyle {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer {
  margin-top: 20px;
}
