.loginItem {
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}
.buttonBlock {
  width: 100%;
}
.textForm {
  font-size: 12px;
  text-align: left;
  width: 100%;
}

.globalErrorMesage {
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.apiTextFieldWrapper {
  padding: 10px 5px 10px 20px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}