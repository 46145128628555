.paperStyle {
  padding: 20px;
  width: 542px;
  margin: 20px auto;
}

.loginItem {
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}
.buttonBlock {
  width: 100%;
}
.textForm {
  font-size: 12px;
  text-align: left;
  width: 100%;
}

.paperStyle {
  padding: 10px;
  width: 542px;
  margin: 10px auto;
  max-height: 750px;
  overflow: auto;
}

.globalErrorMesage {
  font-size: 12px;
  text-align: center;
  width: 100%;
}
